//登录 Login
export const Login = {
  title: 'TRUNG TÂM HỖ TRỢ KHÁCH HÀNG',
  dec: 'Vui lòng đăng nhập để được hỗ trợ tốt nhất',
  password: 'Mật khẩu',
  confirm: 'Đăng nhập',
  other: 'Hoặc sử dụng',
  accountPlaceholder: 'Nhập Email hoặc số điện thoại',
  passwordPlaceholder: 'Nhập mật khẩu',
  nofb: 'fb chưa được tải',
}

//顶部标签 Home
export const Home = {
  exit: 'Đăng xuất',
  sysName: 'Hệ thống Ticket',
  confirmExit: 'Xác nhận đăng xuất',
  remain: 'Nhắc nhở',
  home:'Ticket của tôi',
  userOrder:'Gửi Ticket',
  table:'Danh sách Ticket',
}

//顶部标签 Home
export const userOrder = {
  chooseGame: 'Chọn game',
  chooseGameplaceholder: 'Xin chọn game',
  chooseServer: 'Chọn server',
  chooseServerplaceholder: 'Xin chọn server',
  chooseRole: 'Chọn nhân vật',
  chooseRoleplaceholder: 'Xin chọn nhân vật',
  problemStyle: 'Loại vấn đề',
  problemStyleplaceholder: 'Xin chọn loại vấn đề',
  decPlaceholder: 'Xin chọn Mô tả vấn đề',
  contactPlaceholder: 'Xin nhập email hoặc số điện thoại',
  unusualLogin: 'Đăng nhập bất thường',
  unusualAccount: 'Tài khoản bất thường',
  unusualNap: 'Nạp bất thường',
  unusualOther: 'Khác',
  decProblem: 'Mô tả vấn đề',
  uploadImg: 'Upload hình ảnh',
  confirmUpload: 'Xác nhận upload',
  confirmUploadDec: 'Chỉ có thể tải lên file định dạng jpg/png và không được quá 20MB',
  uploadVideo: 'Upload Video clip',
  confirmUploadVideoDec: 'Chỉ có thể tải lên file mp4 (video clip)',
  contact: 'Xin nhập địa chỉ email hoặc link Facebook',
  confirm: 'Gửi biểu mẫu',
  maxChooseImg: 'Hiện chỉ được chọn tối đa 3 file',
  onlyJpg: 'Chỉ có thể tải lên hình ảnh định dạng jpg/png!',
  maxSize: 'File tải lên không được quá 20MB!',
  uploadVideoMsg: 'Xin tải lên file video clip!',
  uploadSuccess: 'Upload thành công!',
  uploadFail: 'Upload thất bại,',
  onlyOneFile: 'Hiện chỉ được chọn tối đa 1 file',
}

//表格
export const table = {
  decProblem:'Mô tả vấn đề',
  status:'Trạng thái',
  statusPlaceholder:'Xin chọn trạng thái Ticket',
  problemStyle: 'Loại vấn đề',
  problemStyleplaceholder: 'Xin chọn loại vấn đề',
  unusualLogin: 'Đăng nhập bất thường',
  unusualAccount: 'Tài khoản bất thường',
  unusualNap: 'Nạp bất thường',
  unusualOther: 'Khác',
  search:'Tra cứu',
  Reset:'Reset',
  noData:'Tạm không có dữ liệu',
  role:'Nhân vật',
  contact:'Phương thức liên hệ',
  star:'Đánh giá mức độ',
  img:'Hình ảnh',
  video:'Video clip',
  noUseVideo:'Trình duyệt của bạn không hỗ trợ định dạng video này.',
  operate:'Thao tác',
  reOpenTicket:'Mở lại Ticket',
  closeTicket:'Đóng Ticket',
  replyTicket:'Phản hồi Ticket',
  evaluate:'Đánh giá',
  feedback:'Xem phản hồi',
  Processing:'Đang xử lý',
  Processed:'Đã xử lý',
  closed:'Đã đóng',
  confirmOpenTicket:'Xác nhận mở lại Ticket?',
  remail:'Nhắc nhở',
  confirmCloseTicket:'Xác nhận đóng Ticket?',
}

//查看客服回复
export const replyDialog = {
  title:'CSKH phản hồi',
  noUseVideo:'Trình duyệt của bạn không hỗ trợ định dạng video này.',
  user:'Người dùng',
  send:'gửi',
  confirm:'Xác nhận',
}

//评价
export const starDialog = {
  title:'Đánh giá',
  satisfaction:'Mức độ hài lòng',
  other:'Ý kiến khác',
  Reset:'Reset',
  satisfaction1:'Rất kém',
  satisfaction2:'Kém',
  satisfaction3:'Bình thường',
  satisfaction4:'Hài lòng',
  satisfaction5:'Rất hài lòng',
  gradePlaceholder:'Xin chọn mức độ đánh giá',
  commentPlaceholder:'Xin nhập đánh giá',
}

//工单回复
export const userReplyDialog = {
  title:'Phản hồi Ticket',
  decProblem:'Mô tả vấn đề',
  uploadImg: 'Upload hình ảnh',
  confirmUpload: 'Xác nhận upload',
  confirmUploadDec: 'Chỉ có thể tải lên file định dạng jpg/png và không được quá 20MB',
  uploadVideo: 'Upload Video clip',
  confirmUploadVideoDec: 'Chỉ có thể tải lên file mp4 (video clip)',
  confirm:'Xác nhận',
  decPlaceholder: 'Xin chọn Mô tả vấn đề',
  maxChooseImg: 'Hiện chỉ được chọn tối đa 3 file',
  onlyJpg: 'Chỉ có thể tải lên hình ảnh định dạng jpg/png!',
  maxSize: 'File tải lên không được quá 20MB!',
  uploadVideoMsg: 'Xin tải lên file video clip!',
  uploadSuccess: 'Upload thành công!',
  uploadFail: 'Upload thất bại,',
  onlyOneFile: 'Hiện chỉ được chọn tối đa 1 file',
}
//登录 Login
export const Login = {
  title: 'Customer Support Center',
  dec: 'Please log in for the best support',
  password: 'password',
  confirm: 'Login',
  other: 'or use',
  accountPlaceholder: 'Enter email or phone number',
  passwordPlaceholder: 'Enter password',
  nofb: 'fbNot loaded',
}

//顶部标签 Home
export const Home = {
  exit: 'Log out',
  sysName: 'Ticketing system',
  confirmExit: 'Confirm Exit',
  remain: 'Reminder',
  home: 'My Tickets',
  userOrder: 'Submit a Ticket',
  table: 'Ticket List',
}

//顶部标签 Home
export const userOrder = {
  chooseGame: 'Choose a game',
  chooseGameplaceholder: 'Please select a game',
  chooseServer: 'Select Server',
  chooseServerplaceholder: 'Please select a server',
  chooseRole: 'Select Role',
  chooseRoleplaceholder: 'Please select a role',
  problemStyle: 'Question type',
  problemStyleplaceholder: 'Please select the type of question',
  decPlaceholder: 'Please select a description of the issue',
  contactPlaceholder: 'Please enter your email or phone number',
  unusualLogin: 'Login Exception',
  unusualAccount: 'Account Exception',
  unusualNap: 'Deposit Abnormal',
  unusualOther: 'Other',
  decProblem: 'Describe the problem',
  uploadImg: 'Upload image',
  confirmUpload: 'Confirm upload',
  confirmUploadDec: 'Only jpg/png can be uploaded.',
  uploadVideo: 'Upload video file',
  confirmUploadVideoDec: 'Only MP can be uploaded.',
  contact: 'Please enter your email address or Facebook.',
  confirm: 'Submit Information',
  maxChooseImg: 'Currently only a maximum of 3 files can be selected',
  onlyJpg: 'Only jpg/png can be uploaded.',
  maxSize: 'File uploads cannot exceed 20MB',
  uploadVideoMsg: 'Please upload a video file!',
  uploadSuccess: 'Upload successful',
  uploadFail: 'Upload failed,',
  onlyOneFile: 'Currently only a maximum of one file can be selected',
}

//表格
export const table = {
  decProblem: 'Problem description',
  status: 'Status',
  statusPlaceholder: 'Please select ticket status',
  problemStyle: 'Question type',
  problemStyleplaceholder: 'Please select the type of question',
  unusualLogin: 'Login Exception',
  unusualAccount: 'Account Exception',
  unusualNap: 'Deposit Abnormal',
  unusualOther: 'Other',
  search: 'Search',
  Reset: 'reset',
  noData: 'No data yet',
  role: 'Role',
  contact: 'Contact',
  star: 'Star rating',
  img: 'Picture',
  video: 'video',
  noUseVideo: 'Your browser does not support this video format. ',
  operate: 'Action',
  reOpenTicket: 'Reopen ticket',
  closeTicket: 'Close ticket',
  replyTicket: 'Ticket reply',
  evaluate: 'Assessment',
  feedback: 'View reply',
  Processing: 'Processing',
  Processed: 'Processed',
  closed: 'Close',
  confirmOpenTicket: 'Are you sure to reopen the ticket?',
  remail: 'Reminder',
  confirmCloseTicket: 'Confirm closing the ticket?',
}

//查看客服回复
export const replyDialog = {
  title: 'Customer Service Reply',
  noUseVideo: 'Your browser does not support this video format. ',
  user: 'User',
  send: 'send',
  confirm: 'Confirm',
}

//评价
export const starDialog = {
  title: 'Evaluation',
  satisfaction: 'Satisfaction',
  other: 'Other comments',
  Reset: 'reset',
  satisfaction1: 'Very poor',
  satisfaction2: 'Poor',
  satisfaction3: 'Normal',
  satisfaction4: 'Satisfied',
  satisfaction5: 'Very satisfied',
  gradePlaceholder: 'Please select a star rating',
  commentPlaceholder: 'Please enter a review',
}

//工单回复
export const userReplyDialog = {
  title: 'Ticket reply',
  decProblem: 'Describe the problem',
  uploadImg: 'Upload image',
  confirmUpload: 'Confirm upload',
  confirmUploadDec: 'Only jpg/png can be uploaded.',
  uploadVideo: 'Upload video file',
  confirmUploadVideoDec: 'Only MP can be uploaded.',
  confirm: 'Confirm',
  decPlaceholder: 'Please select a description of the issue',
  maxChooseImg: 'Currently only a maximum of 3 files can be selected',
  onlyJpg: 'Only jpg/png can be uploaded.',
  maxSize: 'File uploads cannot exceed 20MB',
  uploadVideoMsg: 'Please upload a video file!',
  uploadSuccess: 'Upload successful',
  uploadFail: 'Upload failed,',
  onlyOneFile: 'Currently only a maximum of one file can be selected',
}
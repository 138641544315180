<template>
  <section>
    <el-row class="container">
      <el-col class="main" :span="24">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="180px"
          class="demo-ruleForm login-container"
        >
          <!-- <h3 class="title">工单提交</h3> -->
          <el-form-item :label="$t('userOrder.chooseGame')" prop="game_id">
            <el-select
              @change="handleGameChange"
              v-model="ruleForm.game_id"
              :placeholder="$t('userOrder.chooseGameplaceholder')"
            >
              <el-option
                :label="item.game_name"
                :value="item.game_id"
                v-for="item in servers_roles.game"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userOrder.chooseServer')" prop="server_id">
            <el-select
              @change="handleServerChange"
              v-model="ruleForm.server_id"
              :placeholder="$t('userOrder.chooseServerplaceholder')"
            >
              <el-option
                :label="item.server_name"
                :value="item.server_id"
                v-for="item in servers_roles.server"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userOrder.chooseRole')" prop="role_id">
            <el-select
              v-model="ruleForm.role_id"
              :placeholder="$t('userOrder.chooseRoleplaceholder')"
            >
              <el-option
                :label="item.role_name"
                :value="item.role_id"
                v-for="item in servers_roles.roles"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userOrder.problemStyle')" prop="type">
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('userOrder.problemStyleplaceholder')"
            >
              <!-- <el-option label="Login exception" value="1"></el-option>
              <el-option label="Account exception" value="2"></el-option>
              <el-option label="Top up exception" value="3"></el-option> -->
              <el-option
                :label="$t('userOrder.unusualLogin')"
                value="1"
              ></el-option>
              <el-option
                :label="$t('userOrder.unusualAccount')"
                value="2"
              ></el-option>
              <el-option
                :label="$t('userOrder.unusualNap')"
                value="3"
              ></el-option>
              <el-option
                :label="$t('userOrder.unusualOther')"
                value="4"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userOrder.decProblem')" prop="description">
            <el-input type="textarea" v-model="ruleForm.description"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userOrder.uploadImg')" prop="imgUrl">
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :before-upload="handlebeforeUpload"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              accept="image/jpeg, image/png"
              :file-list="fileImgList"
            >
              <el-button size="small" type="primary">
                {{ $t("userOrder.confirmUpload") }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t("userOrder.confirmUploadDec") }}
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('userOrder.uploadVideo')" prop="videoUrl">
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              :before-upload="beforeUpload"
              :on-exceed="onExceed"
              :on-success="onSuccess"
              :on-error="onError"
              accept="video/*"
              :limit="1"
              :file-list="fileVideoList"
            >
              <el-button slot="trigger" size="small" type="primary">
                {{ $t("userOrder.confirmUpload") }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t("userOrder.confirmUploadVideoDec") }}
              </div>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item label="Phương thức liên hệ" prop="contact">
            <el-input
              v-model="ruleForm.contact"
              placeholder="Xin nhập email hoặc số điện thoại"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="Email/Facebook" prop="contact">
            <el-input
              v-model="ruleForm.contact"
              :placeholder="$t('userOrder.contact')"
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="width: 100%"
              @click.native.prevent="handleSubmit"
              :loading="logining"
              >{{ $t("userOrder.confirm") }}</el-button
            >
            <!--<el-button @click.native.prevent="handleReset">重置</el-button>-->
          </el-form-item>
        </el-form>
      </el-col>
      <!-- <myOrderDialog ref="myOrderDialog" /> -->
    </el-row>
  </section>
</template>

<script>
import { serverAndRoleInfoApi, ticketApi } from "../../utils/api";
// import myOrderDialog from "./components/myOrderDialog";
export default {
  name: "",
  mixins: [],
  // components: {
  //   myOrderDialog,
  // },
  props: {},
  data() {
    return {
      sysUserName: localStorage.getItem("username"),
      logining: false,
      token: localStorage.getItem("token"),
      ruleForm: {
        game_id: "",
        server_id: "",
        role_id: "",
        type: "",
        description: "",
        contact: "",
        imgUrl: [],
        videoUrl: "",
      },
      servers_roles: {
        game: [],
        server: [],
        roles: [],
      }, //区服角色
      rules: {
        game_id: [
          {
            required: true,
            message: this.$t("userOrder.chooseGameplaceholder"),
            trigger: "blur",
          },
        ],
        server_id: [
          {
            required: true,
            message: this.$t("userOrder.chooseServerplaceholder"),
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: this.$t("userOrder.chooseRoleplaceholder"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("userOrder.problemStyleplaceholder"),
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: this.$t("userOrder.decPlaceholder"),
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t("userOrder.contactPlaceholder"),
            trigger: "blur",
          },
        ],
      },
      fileList: [], //图片资源
      fileImgList: [],
      videoUrl: "",
      fileVideoList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getServerAndRoleInfo();
  },
  mounted() {},
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.videoUrl = "";
      this.fileImgList = [];
      this.fileVideoList = [];
    },
    //获取游戏区服角色接口
    getServerAndRoleInfo() {
      let params = {
        token: this.token,
      };
      serverAndRoleInfoApi(params).then((res) => {
        if (res.code == 200) {
          this.servers_roles.game = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //选择游戏
    handleGameChange(e) {
      let server = this.servers_roles.game.filter((item) => {
        return item.game_id === e;
      });
      if (server[0].server && server[0].server.length > 0) {
        this.servers_roles.server = server[0].server;
        this.servers_roles.roles = this.servers_roles.server[0].role;
        this.ruleForm.server_id = this.servers_roles.server[0].server_id;
        this.ruleForm.role_id = this.servers_roles.server[0].role[0].role_id;
      } else {
        this.servers_roles.server = "";
        this.servers_roles.roles = "";
        this.ruleForm.server_id = "";
        this.ruleForm.role_id = "";
      }
    },
    //选择区服
    handleServerChange(e) {
      let roles = this.servers_roles.server.filter((item) => {
        return item.server_id === e;
      });
      if (roles) {
        this.servers_roles.roles = roles[0].role;
        this.ruleForm.role_id = this.servers_roles.roles[0].role_id;
      }
    },
    handleSubmit(ev) {
      var _this = this;
      this.ruleForm.imgUrl = this.fileList;
      this.ruleForm.videoUrl = this.videoUrl;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ..._this.ruleForm,
            token: _this.token,
          };
          ticketApi(params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.handleReset();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess(res, file) {
      if (res.code === 200) {
        this.fileList.push(res.data.url);
      }
    },
    handleRemove(file, fileList) {
      let list = [];
      fileList.forEach((item) => {
        list.push(item.response.data.url);
      });
      this.fileList = list;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("userOrder.maxChooseImg"));
    },
    handlebeforeUpload(files, fileList) {
      return new Promise((resolve, reject) => {
        const suffix =
          files.type === "image/jpg" ||
          files.type === "image/png" ||
          files.type === "image/jpeg";
        const isLt1M = files.size / 1024 / 1024 < 20;
        if (!suffix) {
          this.$message.error(this.$t("userOrder.onlyJpg"));
          return reject(false);
        }
        if (!isLt1M) {
          this.$message.error(this.$t("userOrder.maxSize"));
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
        return resolve(true);
      });
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      if (file.type.startsWith("video/")) {
        // 限制文件大小为50MB
        const MAX_SIZE = 20 * 1024 * 1024;
        if (file.size > MAX_SIZE) {
          this.$message.error(this.$t("userOrder.maxSize"));
          return false;
        }
        // 文件大小符合要求，可以继续上传
        return true;
      } else {
        this.$message.error(this.$t("userOrder.uploadVideoMsg"));
        return false;
      }
    },
    onSuccess(res, file) {
      this.$message.success(this.$t("userOrder.uploadSuccess"));
      if (res.code === 200) {
        this.videoUrl = res.data.url;
      }
      // 处理上传成功后的逻辑，例如更新UI或者处理其他数据
    },
    onError(res, file, fileList) {
      this.$message.error(this.$t("userOrder.uploadFail") + res.msg);
      // 处理上传失败后的逻辑
    },
    onExceed(files, fileList) {
      this.$message.warning(this.$t("userOrder.onlyOneFile"));
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-select {
  width: 100%;
}
.container {
  // position: absolute;
  // top: 0px;
  // bottom: 0px;
  width: 100%;
  // .main{
  //   height: 620px;
  //   overflow: auto;
  // }
  .header {
    position: fixed;
    top: 0;
    height: 60px;
    line-height: 60px;
    background: #20a0ff;
    color: #fff;
    .logout {
      cursor: pointer;
    }
    .myOrder {
      display: inline-block;
      margin-right: 20px;
      padding: 0 10px;
      cursor: pointer;
      height: 100%;
    }
    .myOrder:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    .userinfo {
      text-align: right;
      padding-right: 35px;
      float: right;
      .userinfo-inner {
        cursor: pointer;
        color: #fff;
        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin: 10px 10px 10px 0px;
          float: left;
        }
      }
    }
    .logo {
      //width:230px;
      height: 60px;
      font-size: 22px;
      padding-left: 20px;
      padding-right: 20px;
      border-color: rgba(238, 241, 146, 0.3);
      border-right-width: 1px;
      border-right-style: solid;
      img {
        width: 40px;
        float: left;
        margin: 10px 10px 10px 18px;
      }
      .txt {
        color: #fff;
      }
    }
    .logo-width {
      width: 230px;
    }
    .logo-collapse-width {
      width: 60px;
    }
    .tools {
      padding: 0px 23px;
      width: 14px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .login-container {
    /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
    // -webkit-border-radius: 5px;
    // border-radius: 5px;
    // -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: 0 0;
    width: 40%;
    padding: 35px 35px 15px 35px;
    background: #fff;
    // border: 1px solid #eaeaea;
    // box-shadow: 0 0 25px #cac6c6;
    .title {
      margin: 0px auto 40px auto;
      text-align: center;
      color: #505458;
    }
    .remember {
      margin: 0px 0px 35px 0px;
    }
    .no-autofill-pwd {
      /deep/ .el-input__inner {
        -webkit-text-security: disc !important;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .container {
    //   .main{
    //   height: 520px;
    // }
    .login-container {
      width: 100%;
      padding: 20px 5px 20px 5px;
    }
  }
}
</style>

<template>
  <div class="">
    <el-dialog
      :title="$t('starDialog.title')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('starDialog.satisfaction')" prop="grade">
          <!-- <el-input v-model="ruleForm.name"></el-input> -->
          <el-rate v-model="ruleForm.grade" :colors="colors" :texts="texts" show-text> </el-rate>
        </el-form-item>
        <el-form-item :label="$t('starDialog.other')" prop="comment">
          <el-input
            type="textarea"
            :rows="5"
            :placeholder="$t('starDialog.other')"
            v-model="ruleForm.comment"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="submitBtn">
          <el-button type="primary" @click="submitForm('ruleForm')">
            {{$t('starDialog.title')}}
          </el-button>
          <el-button @click="resetForm('ruleForm')">{{$t('starDialog.Reset')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { appraiseApi } from "@/utils/api";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      grade: null,
      token: localStorage.getItem("token"),
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      texts:[this.$t('starDialog.satisfaction1'), this.$t('starDialog.satisfaction2'), this.$t('starDialog.satisfaction3'), this.$t('starDialog.satisfaction4'), this.$t('starDialog.satisfaction5')],
      ruleForm: {
        grade: 0,
        comment: "",
      },
      starData: {},
      rules: {
        grade: [
          {
            required: true,
            message: this.$t('starDialog.gradePlaceholder'),
            trigger: "blur",
          },
        ],
        comment: [
          { required: true, message: this.$t('starDialog.commentPlaceholder'), trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ticket_id: this.starData.id,
            token: this.token,
            ...this.ruleForm,
          };
          appraiseApi(params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.$parent.getUsers();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
/deep/ .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
  text-align: center;
}
/deep/ .el-rate__icon {
  font-size: 45px;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .submitBtn {
    /deep/.el-form-item__content {
      margin-left: 0 !important;
    }
  }

  /deep/ .el-rate__icon {
    font-size: 25px;
  }
}
</style>

//登录 Login
export const Login = {
  title: 'ศูนย์บริการลูกค้า',
  dec: 'โปรดเข้าสู่ระบบเพื่อรับการสนับสนุนที่ดีที่สุด',
  password: 'รหัสผ่าน',
  confirm: 'เข้าสู่ระบบ',
  other: 'หรือใช้',
  accountPlaceholder: 'ป้อนอีเมลหรือหมายเลขโทรศัพท์',
  passwordPlaceholder: 'ป้อนรหัสผ่าน',
  nofb: 'fbไม่โหลด',
}

//顶部标签 Home
export const Home = {
  exit: 'ออกจากระบบ',
  sysName: 'ระบบการออกตั๋ว',
  confirmExit: 'ยืนยันทางออก',
  remain: 'เตือนความจํา',
  home:'สั่งงานของฉัน',
  userOrder:'ส่งงานสั่งทำ',
  table:'รายการสั่งงาน',
}

//顶部标签 Home
export const userOrder = {
  chooseGame: 'เลือกเกม',
  chooseGameplaceholder: 'โปรดเลือกเกม',
  chooseServer: 'เลือกเซิร์ฟเวอร์',
  chooseServerplaceholder: 'โปรดเลือกเซิร์ฟเวอร์',
  chooseRole: 'เลือกบทบาท',
  chooseRoleplaceholder: 'โปรดเลือกบทบาท',
  problemStyle: 'ประเภทคําถาม',
  problemStyleplaceholder: 'โปรดเลือกประเภทของคําถาม',
  decPlaceholder: 'โปรดเลือกคําอธิบายของปัญหา',
  contactPlaceholder: 'โปรดป้อนอีเมลหรือหมายเลขโทรศัพท์ของคุณ',
  unusualLogin: 'ข้อยกเว้นการเข้าสู่ระบบ',
  unusualAccount: 'ข้อยกเว้นบัญชี',
  unusualNap: 'เงินฝากผิดปกติ',
  unusualOther: 'อื่นๆ',
  decProblem: 'อธิบายปัญหา',
  uploadImg: 'อัปโหลดรูปภาพ',
  confirmUpload: 'ยืนยันการอัปโหลด',
  confirmUploadDec: 'อัปโหลดได้เฉพาะ jpg/png เท่านั้น',
  uploadVideo: 'อัปโหลดไฟล์วิดีโอ',
  confirmUploadVideoDec: "อัปโหลดได้เฉพาะ ส.ส.เท่านั้น",
  contact: 'โปรดป้อนที่อยู่อีเมลหรือ Facebook ของคุณ',
  confirm: 'ส่งข้อมูล',
  maxChooseImg: 'ขณะนี้สามารถเลือกได้สูงสุด 3 ไฟล์เท่านั้น',
  onlyJpg: 'อัปโหลดได้เฉพาะ jpg/png เท่านั้น',
  maxSize: 'การอัปโหลดไฟล์ต้องไม่เกิน 20MB',
  uploadVideoMsg: 'โปรดอัปโหลดไฟล์วิดีโอ!' ,
  uploadSuccess: 'อัปโหลดสําเร็จ',
  uploadFail: 'อัปโหลดล้มเหลว',
  onlyOneFile: 'ขณะนี้สามารถเลือกได้สูงสุดหนึ่งไฟล์เท่านั้น',
}

//表格
export const table = {
  decProblem:'คําอธิบายปัญหา',
  status:'สถานะ',
  statusPlaceholder:'โปรดเลือกสถานะตั๋ว',
  problemStyle: 'ประเภทคําถาม',
  problemStyleplaceholder: 'โปรดเลือกประเภทของคําถาม',
  unusualLogin: 'ข้อยกเว้นการเข้าสู่ระบบ',
  unusualAccount: 'ข้อยกเว้นบัญชี',
  unusualNap: 'เงินฝากผิดปกติ',
  unusualOther: 'อื่นๆ',
  search:'ค้นหา',
  Reset:'รีเซ็ต',
  noData: 'ยังไม่มีข้อมูล',
  role:'บทบาท',
  contact: 'ติดต่อ',
  star: 'ระดับดาว',
  img:'รูปภาพ',
  video:'วิดีโอ',
  noUseVideo: 'เบราว์เซอร์ของคุณไม่รองรับรูปแบบวิดีโอนี้ ',
  operate:'การกระทํา',
  reOpenTicket:'ตั๋วเปิดใหม่',
  closeTicket:'ปิดตั๋ว',
  replyTicket:'ตอบกลับตั๋ว',
  evaluate: 'การประเมิน',
  feedback:'ดูคําตอบ',
  Processing: 'การประมวลผล',
  Processed:'ประมวลผลแล้ว',
  closed:'ปิด',
  confirmOpenTicket:'คุณแน่ใจหรือว่าจะเปิดตั๋วอีกครั้ง?',
  remail:'เตือนความจํา',
  confirmCloseTicket:'ยืนยันปิดตั๋ว?',
}

//查看客服回复
export const replyDialog = {
  title:'ตอบกลับฝ่ายบริการลูกค้า',
  noUseVideo: 'เบราว์เซอร์ของคุณไม่รองรับรูปแบบวิดีโอนี้ ',
  user:'ผู้ใช้',
  send:'ส่ง',
  confirm:'ยืนยัน',
}

//评价
export const starDialog = {
  title:'การประเมินผล',
  satisfaction: 'ความพึงพอใจ',
  other: 'ความคิดเห็นอื่นๆ',
  Reset:'รีเซ็ต',
  satisfaction1: 'แย่มาก',
  satisfaction2: 'แย่',
  satisfaction3: 'ปกติ',
  satisfaction4: 'พอใจ',
  satisfaction5: 'พอใจมาก',
  gradePlaceholder: 'โปรดเลือกระดับดาว',
  commentPlaceholder: 'โปรดป้อนรีวิว',
}

//工单回复
export const userReplyDialog = {
  title:'ตอบกลับตั๋ว',
  decProblem:'อธิบายปัญหา',
  uploadImg: 'อัปโหลดรูปภาพ',
  confirmUpload: 'ยืนยันการอัปโหลด',
  confirmUploadDec: 'อัปโหลดได้เฉพาะ jpg/png เท่านั้น',
  uploadVideo: 'อัปโหลดไฟล์วิดีโอ',
  confirmUploadVideoDec: 'อัปโหลดได้เฉพาะ ส.ส.เท่านั้น',
  confirm: 'ยืนยันแล้ว',
  decPlaceholder: 'โปรดเลือกคําอธิบายของปัญหา',
  maxChooseImg: 'ขณะนี้สามารถเลือกได้สูงสุด 3 ไฟล์เท่านั้น',
  onlyJpg: 'อัปโหลดได้เฉพาะ jpg/png เท่านั้น',
  maxSize: 'การอัปโหลดไฟล์ต้องไม่เกิน 20MB',
  uploadVideoMsg: 'โปรดอัปโหลดไฟล์วิดีโอ!', 
  uploadSuccess: 'อัปโหลดสําเร็จ',
  uploadFail: 'อัปโหลดล้มเหลว',
  onlyOneFile: 'ขณะนี้สามารถเลือกได้สูงสุดหนึ่งไฟล์เท่านั้น',
}
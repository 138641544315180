//登录 Login
export const Login = {
  title: 'Pusat Dukungan Pelanggan',
  dec: 'Silakan masuk untuk dukungan terbaik',
  password: 'kata sandi',
  confirm: 'Login',
  other: 'atau gunakan',
  accountPlaceholder: 'Masukkan email atau nomor telepon',
  passwordPlaceholder: 'Masukkan kata sandi',
  nofb: 'fbTidak dimuat',
}

//顶部标签 Home
export const Home = {
  exit: 'Keluar',
  sysName: 'Sistem tiket',
  confirmExit: 'Konfirmasi Keluar',
  remain: 'Pengingat',
  home: 'Perintah kerja saya',
  userOrder: 'Kirimkan perintah kerja',
  table: 'Daftar perintah kerja',
}

//顶部标签 Home
export const userOrder = {
  chooseGame: 'Pilih game',
  chooseGameplaceholder: 'Silakan pilih game',
  chooseServer: 'Pilih Server',
  chooseServerplaceholder: 'Silakan pilih server',
  chooseRole: 'Pilih Peran',
  chooseRoleplaceholder: 'Silakan pilih peran',
  problemStyle: 'Jenis pertanyaan',
  problemStyleplaceholder: 'Silakan pilih jenis pertanyaan',
  decPlaceholder: 'Pilih deskripsi masalah',
  contactPlaceholder: 'Silakan masukkan email atau nomor telepon Anda',
  unusualLogin: 'Pengecualian Login',
  unusualAccount: 'Pengecualian Akun',
  unusualNap: 'Deposit Tidak Normal',
  unusualOther: 'Lainnya',
  decProblem: 'Jelaskan masalahnya',
  uploadImg: 'Unggah gambar',
  confirmUpload: 'Konfirmasi upload',
  confirmUploadDec: 'Hanya jpg/png yang dapat diunggah.',
  uploadVideo: 'Unggah file video',
  confirmUploadVideoDec: 'Hanya MP yang dapat diunggah.',
  contact: 'Silakan masukkan alamat email atau Facebook Anda.',
  confirm: 'Kirim Informasi',
  maxChooseImg: 'Saat ini hanya maksimal 3 file yang dapat dipilih',
  onlyJpg: 'Hanya jpg/png yang dapat diunggah.',
  maxSize: 'Unggahan file tidak boleh melebihi 20MB',
  uploadVideoMsg: "Silakan unggah file video!",
  uploadSuccess: 'Upload berhasil',
  uploadFail: 'Upload gagal,',
  onlyOneFile: 'Saat ini hanya maksimal satu file yang dapat dipilih',
}

//表格
export const table = {
  decProblem: 'Deskripsi masalah',
  status: 'Status',
  statusPlaceholder: 'Silakan pilih status tiket',
  problemStyle: 'Jenis pertanyaan',
  problemStyleplaceholder: 'Silakan pilih jenis pertanyaan',
  unusualLogin: 'Pengecualian Login',
  unusualAccount: 'Pengecualian Akun',
  unusualNap: 'Deposit Tidak Normal',
  unusualOther: 'Lainnya',
  search: 'Cari',
  Reset: 'atur ulang',
  noData: 'Belum ada data',
  role: 'Peran',
  contact: 'Kontak',
  star: 'Peringkat bintang',
  img: 'Gambar',
  video: 'video',
  noUseVideo: 'Browser Anda tidak mendukung format video ini. ',
  operate: 'Aksi',
  reOpenTicket: 'Buka kembali tiket',
  closeTicket: 'Tutup tiket',
  replyTicket: 'Balasan tiket',
  evaluate: 'Penilaian',
  feedback: 'Lihat balasan',
  Processing: 'Memproses',
  Processed: 'Diproses',
  closed: 'Tutup',
  confirmOpenTicket: 'Apakah Anda yakin untuk membuka kembali tiket?',
  remail: 'Pengingat',
  confirmCloseTicket: 'Konfirmasi penutupan tiket?',
}

//查看客服回复
export const replyDialog = {
  title: 'Balasan Layanan Pelanggan',
  noUseVideo: 'Browser Anda tidak mendukung format video ini. ',
  user: 'Pengguna',
  send: 'kirim',
  confirm: 'Konfirmasi',
}

//评价
export const starDialog = {
  title: 'Evaluasi',
  satisfaction: 'Kepuasan',
  other: 'Komentar lain',
  Reset: 'atur ulang',
  satisfaction1: 'Sangat miskin',
  satisfaction2: 'Miskin',
  satisfaction3: 'Normal',
  satisfaction4: 'Puas',
  satisfaction5: 'Sangat puas',
  gradePlaceholder: 'Silakan pilih peringkat bintang',
  commentPlaceholder: 'Silakan masukkan ulasan',
}

//工单回复
export const userReplyDialog = {
  title: 'Balasan tiket',
  decProblem: 'Jelaskan masalahnya',
  uploadImg: 'Unggah gambar',
  confirmUpload: 'Konfirmasi upload',
  confirmUploadDec: 'Hanya jpg/png yang dapat diunggah.',
  uploadVideo: 'Unggah file video',
  confirmUploadVideoDec: 'Hanya MP yang dapat diunggah.',
  confirm: 'Konfirmasi',
  decPlaceholder: 'Pilih deskripsi masalah',
  maxChooseImg: 'Saat ini hanya maksimal 3 file yang dapat dipilih',
  onlyJpg: 'Hanya jpg/png yang dapat diunggah.',
  maxSize: 'Unggahan file tidak boleh melebihi 20MB',
  uploadVideoMsg: "Silakan unggah file video!",
  uploadSuccess: 'Upload berhasil',
  uploadFail: 'Upload gagal,',
  onlyOneFile: 'Saat ini hanya maksimal satu file yang dapat dipilih',
}
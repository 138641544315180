<template>
  <div class="">
    <el-dialog
      :title="$t('userReplyDialog.title')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      center
    >
      <div class="dialogContent">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="150px"
          class="demo-ruleForm login-container"
        >
          <el-form-item :label="$t('userReplyDialog.decProblem')" prop="reply">
            <el-input type="textarea" v-model="ruleForm.reply"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userReplyDialog.uploadImg')" prop="imgUrl">
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :before-upload="handlebeforeUpload"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              accept="image/jpeg, image/png"
              :file-list="fileImgList"
            >
              <el-button size="small" type="primary">{{ $t('userReplyDialog.confirmUpload') }}</el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t('userReplyDialog.confirmUploadDec') }}
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('userReplyDialog.uploadVideo')" prop="videoUrl">
            <el-upload
              class="upload-demo"
              action="https://ticket.muugame.com/api/upload"
              :before-upload="beforeUpload"
              :on-exceed="onExceed"
              :on-success="onSuccess"
              :on-error="onError"
              accept="video/*"
              :limit="1"
              :file-list="fileVideoList"
            >
              <el-button slot="trigger" size="small" type="primary">
                {{ $t('userReplyDialog.confirmUpload') }}
              </el-button>
              <div slot="tip" class="el-upload__tip">{{$t('userReplyDialog.confirmUploadVideoDec')}}</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClick" type="primary">{{ $t('userReplyDialog.confirm') }}</el-button>
        <!-- <el-button @click="dialogVisible = false">关 闭</el-button> -->
      </span>
    </el-dialog>
    <starDialog ref="starDialog" />
  </div>
</template>

<script>
import starDialog from "./starDialog";
import { ticketReplyApi } from "@/utils/api";
export default {
  name: "",
  mixins: [],
  components: { starDialog },
  props: {},
  data() {
    return {
      dialogVisible: false,
      ticket_id: "",
      token: localStorage.getItem("token"),
      ruleForm: {
        reply: "",
        imgUrl: [],
        videoUrl: "",
      },
      rules: {
        reply: [
          { required: true, message: this.$t('userReplyDialog.decPlaceholder'), trigger: "blur" },
        ],
      },
      fileList: [],
      fileImgList: [],
      videoUrl: "",
      fileVideoList: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
      this.videoUrl = "";
      this.fileImgList = [];
      this.fileVideoList = [];
    },
    openStarDialog(data) {
      this.$refs.starDialog.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleReset()
    },
    handleClick() {
      var _this = this;
      this.ruleForm.imgUrl = this.fileList;
      this.ruleForm.videoUrl = this.videoUrl;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ticket_id: this.ticket_id,
            ..._this.ruleForm,
            token: _this.token,
          };
          ticketReplyApi(params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.handleReset();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess(res, file) {
      if (res.code === 200) {
        this.fileList.push(res.data.url);
      }
    },
    handleRemove(file, fileList) {
      let list = [];
      fileList.forEach((item) => {
        list.push(item.response.data.url);
      });
      this.fileList = list;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        this.$t('userReplyDialog.maxChooseImg')
      );
    },
    handlebeforeUpload(files, fileList) {
      return new Promise((resolve, reject) => {
        const suffix =
        files.type === "image/jpg" ||
        files.type === "image/png" ||
        files.type === "image/jpeg";
        const isLt1M = files.size / 1024 / 1024 < 20;
        if (!suffix) {
          this.$message.error(this.$t('userReplyDialog.onlyJpg'));
          return reject(false);
        }
        if (!isLt1M) {
          this.$message.error(this.$t('userReplyDialog.maxSize'));
          return reject(false);
        }
        // 调用[限制图片尺寸]函数
       return resolve(true)
      });
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      if (file.type.startsWith("video/")) {
        // 限制文件大小为50MB
        const MAX_SIZE = 20 * 1024 * 1024;
        if (file.size > MAX_SIZE) {
          this.$message.error(this.$t('userReplyDialog.maxSize'));
          return false;
        }
        // 文件大小符合要求，可以继续上传
        return true;
      } else {
        this.$message.error(this.$t('userReplyDialog.uploadVideoMsg'));
        return false;
      }
    },
    onSuccess(res, file) {
      this.$message.success(this.$t('userReplyDialog.uploadSuccess'));
      console.log(res, file);
      if (res.code === 200) {
        this.videoUrl = res.data.url;
      }
      // 处理上传成功后的逻辑，例如更新UI或者处理其他数据
    },
    onError(res, file, fileList) {
      this.$message.error(this.$t('userReplyDialog.uploadFail') + res.msg);
      // 处理上传失败后的逻辑
    },
    onExceed(files, fileList) {
      this.$message.warning(this.$t('userReplyDialog.onlyOneFile'));
    },
  },
};
</script>

<style scoped lang="less">
.dialogContent {
  height: 300px;
  overflow: auto;
}
.box-card {
  margin-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .dialogContent {
    height: 100%;
  }
}
</style>

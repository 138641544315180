<template>
  <!-- <div>
    <a href="javascript:;" class="login_type_2" @click="getGoogle">
      <img class="facebook" src="../assets/img/google.png" />
      <div>google</div>
    </a>
  </div> -->
  <div>
    <div class="g_id_signin2" id="g_id_signin2"></div>
  </div>
</template>
<script>
import { loginApi } from "../utils/api";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    client_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clientId:
        "698305642286-d168sf42qjnc1rv5piu9c6nhm9tkdvag.apps.googleusercontent.com",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.init(this.handleCredentialResponse);
      }, 100);
      // window.addEventListener("load", this.init(this.handleCredentialResponse));
    });
  },
  async mounted() {
    await this.init(this.handleCredentialResponse);
  },
  methods: {
    init(fn) {
      // window.addEventListener("load", this.gload(fn));
      this.gload(fn);
    },
    gload(fn) {
      if (window.google) {
        window.google.accounts.id.initialize({
          // 主要就是填写client_id
          client_id: this.clientId,
          callback: fn,
          locale: "en-US",
        });
        window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin2"),
          {
            theme: "filled_blue",
            size: "large",
            shape: "circle",
            // width: '336',
            type: "icon",
            text: "signin_with",
          }
        );
      }
    },
    decodeJwtResponse(token) {
      const strings = token.split(".");
      return JSON.parse(
        decodeURIComponent(
          escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))
        )
      );
    },
    async handleCredentialResponse(response) {
      console.log(response);
      if (response) {
        const responsePayload = this.decodeJwtResponse(response.credential);
        // const responsePayload = this.decodeJwtResponse(response.credential);
        // console.log(response);
        // console.log('responsePayload', responsePayload);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);
        this.Login(responsePayload.email);
      }
    },
    Login(access_token) {
      let params = {
        login_type: 3,
        username: access_token,
      };
      loginApi(params).then((data) => {
        this.logining = false;
        if (data.code !== 200) {
          this.$message({
            message: data.msg,
            type: "error",
          });
        } else {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("username", data.data.username);
          this.$router.push({ path: "/userOrder" });
        }
      });
    },
    handleClick() {
      console.log(this.clientId);
    },
  },
  beforeDestroy() {
    window.removeEventListener("load", this.gload());
  },
};
</script>

<style scoped lang="scss"></style>

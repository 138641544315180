import instance from "./axios";


//登录
export const loginApi = (params) => {
  return instance.get('/api/login', { params });
};

//工单列表
export const ticketList = (params) => {
  return instance.get('/api/ticketList', { params });
};

//工单评价
export const appraiseApi = (params) => {
  return instance.get('/api/appraise', { params });
};

//提交工单
export const ticketApi = (params) => {
  return instance.get('/api/ticket', { params });
};

//选择区服角色
export const serverAndRoleInfoApi = (params) => {
  return instance.get('/api/serverAndRoleInfo', { params });
};


//工单关闭
export const ticketCloseApi = (params) => {
  return instance.get('/api/ticketClose', { params });
};

//工单回复列表
export const ticketReplyLogApi = (params) => {
  return instance.get('/api/ticketReplyLog', { params });
};
//工单回复
export const ticketReplyApi = (params) => {
  return instance.get('/api/ticketReply', { params });
};
//重新开启工单
export const restartTicketApi = (params) => {
  return instance.get('/api/restartTicket', { params });
};

// login轮播图
export const bannerApi = (params) => {
  return instance.get('/api/banner', { params });
};
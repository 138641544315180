<template>
  <div>
    <div class="headerRight" v-if=" true || $pathName === 'mgolgames'">
      <img class="headerRightImg" src="@/assets/earth.png" alt="" srcset="" />
      <el-select v-model="lang" @change="changeLang" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "VI",
      showLang: false,
      options: [
        {
          value: "VI",
          label: "Tiếng Việt",
        },
        {
          value: "TH",
          label: "แบบไทย",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "EN",
          label: "English",
        },
        {
          value: "ZH",
          label: "中文",
        },
      ],
    };
  },
  created() {
    // this.changeLang()
    this.lang = localStorage.getItem("locale") || "VI";
  },
  methods: {
    changeLang(e) {
      this.lang = localStorage.getItem("locale") || "VI";
      if (this.lang === "VI") {
        this.lang = e;
        this.$i18n.locale = e;
      } else {
        this.lang = e;
        this.$i18n.locale = e;
      }
      localStorage.setItem("locale", this.lang);
      this.$store.state.lang = this.lang;
      // location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}
.headerRight {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 10px;
  height: 100%;
}
.headerRightImg {
  width: 50px !important;
}
</style>
import Vue from "vue";
import App from "./App";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import zhLang from 'element-ui/lib/locale/lang/zh-CN'; // 引入官方的中文国际化
import locale from './assets/locale/cn'; // 引入自己的
import store from "./vuex/store";
import Vuex from "vuex";
import router from "./router/index";
import axios from "./utils/axios";
import VueI18n from 'vue-i18n'
import dayjs from 'dayjs';
import "font-awesome/css/font-awesome.min.css";

Vue.use(ElementUI, {
  locale: { ...zhLang, ...locale }, // 使用本地的 locale 去覆盖官方的 zhLang
});
Vue.use(Vuex);
Vue.use(VueI18n)
Vue.prototype.$http = axios;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$isMobile = /Mobile|Android|iphone|ipad|ipod|IOS|Macintosh|Surface Pro 7/i.test(navigator.userAgent);

const languageList = ["VI", "ID", "EN", "TH", "ZH"];
const i18n = new VueI18n({
  locale: languageList.includes(localStorage.getItem('locale')) ? localStorage.getItem('locale') : 'VI',
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'ZH': require('./lang/zh'), // 中文语言包
    'EN': require('./lang/en'), // 英文语言包
    'ID': require('./lang/id'), // 印尼语言包
    // 'PH': require('./lang/en'), // 菲律宾语言包
    'TH': require('./lang/th'), // 泰国语言包
    'VI': require('./lang/vn'), // 越南语言包
  }
})


router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    localStorage.removeItem("token");
  }
  let user = localStorage.getItem("token");
  if (!user && to.path != "/login") {
    next({ path: "/login" });
  } else {
    if (to.path == "/") {
      next({ path: "/table" });
    } else {
      next();
    }
  }
});
let pathName = window.location.hostname;
if (pathName.indexOf("mocgame") > 0) {
  Vue.prototype.$pathName = 'mocgame'
} else if (pathName.indexOf("muugame") > 0) {
  Vue.prototype.$pathName = 'muugame'
}else if(pathName.indexOf("mgolgames") > 0){
  Vue.prototype.$pathName = 'mgolgames'
}else{
  Vue.prototype.$pathName = 'mgol'
}

new Vue({
  //el: '#app',
  //template: '<App/>',
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

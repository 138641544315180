<template>
  <div style="width: 100%; height: 100%">
    <div class="header">
      <ChangeLang />
    </div>
    <div class="content content_1" ref="info" id="info">
      <div class="banner" v-if="!$isMobile">
        <div class="carousel">
          <el-carousel arrow="always" height="400px">
            <el-carousel-item v-for="(item, index) in imgPC" :key="index">
              <img
                :src="item.img"
                alt=""
                style="width: 100%; height: 100%"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="banner" v-if="$isMobile">
        <div class="carousel">
          <el-carousel arrow="always" height="400px">
            <el-carousel-item
              v-for="(item, index) in banner_mobile"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
                style="width: 100%; height: 100%"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content-box">
        <div class="content-box__header">
          <div>
            <div class="content-box__header-content">
              <!-- Đăng nhập hệ thống -->
              <div class="title_top">{{ $t("Login.title")}}</div>
              <div class="title_bottom">{{ $t("Login.dec")}}</div>
            </div>
          </div>
        </div>
        <div class="content-box__content">
          <div class="loginForm">
            <el-form
              :model="ruleForm2"
              :rules="rules2"
              ref="ruleForm2"
              label-width="0px"
              class="demo-ruleForm"
            >
              <el-form-item style="text-align: center">
                <el-radio-group
                  v-model="ruleForm2.type"
                  @change="handleTypeChange"
                >
                  <el-radio label="1" value="1">Email</el-radio>
                  <el-radio label="2" value="2">Phone</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="" prop="account">
                <el-input
                  v-model="ruleForm2.account"
                  :placeholder="ruleForm2.type === '1' ? 'Email' : 'phone'"
                >
                  <template slot="prepend" v-if="ruleForm2.type == '2'"
                    >84 -
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  show-password
                  v-model="ruleForm2.password"
                  :placeholder="$t('Login.password')"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="
                    width: 100%;
                    background: rgb(17, 136, 255);
                    border-color: transparent;
                  "
                  @click="handleSubmit2('ruleForm2')"
                  >{{ $t("Login.confirm") }}</el-button
                >
              </el-form-item>
            </el-form>
            <div class="login_other">
              <div class="loginOther">
                <div class="dashLeft"></div>
                <p>{{ $t("Login.other") }}</p>
                <div class="dashRight"></div>
              </div>
              <ul class="list-action">
                <li>
                  <!-- <FbLogin :fb_appid="fb_appid" /> -->
                  <img
                    style="cursor: pointer; width: 42px"
                    @click="fbLogin"
                    src="../assets/img/facebook.png"
                    alt=""
                  />
                </li>
                <li>
                  <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
                  <!-- <div
                  id="g_id_onload"
                  data-context="signin"
                  data-auto_select="true"
                  data-itp_support="true"
                  data-auto_prompt="true"
                >
                  <img
                    id="g_id_signin"
                    style="cursor: pointer"
                    class="pop_register_input_icon"
                    src="../assets/img/google.png"
                  />
                </div> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer ref="Footer" /> -->
  </div>
</template>
<script>
import { loginApi, bannerApi } from "../utils/api";
// import FbLogin from "@/components/FbLogin.vue";
import GoogleLogin from "@/components/GoogleLogin.vue";
import ChangeLang from "@/components/changeLang"
// import Footer from "@/components/Footer";
export default {
  components: {
    GoogleLogin,
    ChangeLang
    // Footer,
  },
  data() {
    return {
      fb_appid: "1114786559496051", //fb登录id
      client_id:
        "698305642286-d168sf42qjnc1rv5piu9c6nhm9tkdvag.apps.googleusercontent.com", //谷歌登录id
      logining: false,
      ruleForm2: {
        account: "",
        password: "",
        type: "1",
      },
      fbIsOK: false,
      imgPC: [], //电脑端轮播图
      banner_mobile: [], //手机端轮播图
      rules2: {
        account: [
          { required: true, message: this.$t("Login.accountPlaceholder"), trigger: "blur" },
          //{ validator: validaePass }
        ],
        password: [
          { required: true, message: this.$t("Login.passwordPlaceholder"), trigger: "blur" },
          //{ validator: validaePass2 }
        ],
      },
      checked: true,
    };
  },
  created() {
   this.autoLogin()
    this.getBannerData();
    this.getFb();
  },
  // components: { FbLogin, GoogleLogin },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.ruleForm2 = userInfo;
    }
  },
  methods: {
    getFb() {
      // fbid
      const fb_appid = "1114786559496051";
      // 加载 facebook SDK
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        this.fbIsOK = true;
        FB.init({
          appId: fb_appid,
          cookie: true,
          xfbml: true,
          version: "v2.8",
        });
      };
    },
    fbLogin(resCallback) {
      if (!fbIsOK) {
        // fb没加载完成
        Msg(this.$t("Login.nofb"));
        return;
      }
      let _this = this;
      FB.login(
        function (response) {
          if (response.status === "connected") {
            FB.api(
              "/me?fields=id,name,token_for_business",
              function (response) {
                console.log(response);
                let params = {
                  username: response.token_for_business,
                  login_type: 3,
                };
                loginApi(params).then((data) => {
                  _this.logining = false;
                  if (data.code !== 200) {
                    _this.$message({
                      message: data.msg,
                      type: "error",
                    });
                  } else {
                    localStorage.setItem("token", data.data.token);
                    localStorage.setItem("username", data.data.username);
                    _this.$router.push({ path: "/userOrder" });
                  }
                });
              }
            );
          } else {
          }
        },
        { scope: "public_profile,email" }
      );
    },
    handleTypeChange(e) {
      let type = JSON.parse(localStorage.getItem("userInfo"));
      if (e === type.type) {
        this.ruleForm2 = type;
      } else {
        this.ruleForm2 = { account: "", password: "", type: e };
      }
    },
    handleReset2() {
      this.$refs.ruleForm2.resetFields();
    },
    getBannerData() {
      bannerApi().then((res) => {
        this.imgPC = res.data.banner_pc;
        this.banner_mobile = res.data.banner_mobile;
      });
    },
    handleSubmit2(ev) {
      var _this = this;
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.logining = true;
          var loginParams = {
            username: this.ruleForm2.account,
            login_type: this.ruleForm2.type,
            password: this.ruleForm2.password,
          };
          loginApi(loginParams).then((data) => {
            this.logining = false;
            if (data.code !== 200) {
              _this.$message({
                message: data.msg,
                type: "error",
              });
            } else {
              localStorage.setItem("token", data.data.token);
              let userInfo = this.ruleForm2;
              if (this.checked) {
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
              } else {
                localStorage.removeItem("userInfo");
              }
              localStorage.setItem("username", data.data.username);
              this.$router.push({ path: "/userOrder" });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    autoLogin(){
      // if (window.location.search.includes("fbclid")) {
      //   window.location.href = window.location.origin + window.location.pathname + window.location.hash
      // }
      let routeToken = this.$route.query.token;
      let username = this.$route.query.username
      if (routeToken) {
        localStorage.setItem("token", routeToken);
      }
      if (username) {
        localStorage.setItem("username", username);
      }
      if (routeToken && username) {
        this.$router.push({ path: "/userOrder" });
      }
    }
  },
};
</script>

<style lang="less" scoped>

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/.el-carousel__indicators--horizontal {
  bottom: 20%;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;

  #g_id_onload {
    width: 48px;
    height: 48px;
    // /deep/ div {
    //   width: 48px;
    // height: 48px;
    // }
  }
  .content-box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 45%;
    min-height: 130px;
    padding: 30px 80px 30px 80px;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(26, 39, 66, 0.3);
    box-shadow: 0 2px 5px 0 rgba(26, 39, 66, 0.3);
    margin-bottom: 30px;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .content-box__header {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;

      .content-box__header-content {
        text-align: center;
        font-weight: 700;
      }
      .title_top{
        font-size: 25px;

      }
      .title_bottom{
        font-size: 13px;
        margin-top: 10px;
      }

      .content-box__header-number {
        color: #000;
        width: 22px;
        height: 22px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
      }

      .content-box__header-number:after {
        content: ". ";
      }
    }
  }

  .login_reg {
    text-align: right;
    color: #1188ff;
    cursor: pointer;
  }

  .loginForm {
    .login_other {
      text-align: center;
      margin-top: 8px;

      .loginOther {
        display: flex;
        align-items: center;

        .dashLeft,
        .dashRight {
          height: 1px;
          width: 100%;
          background-color: hsla(0, 0%, 77.6%, 0.5);
        }

        p {
          width: 50%;
          position: relative;
          display: inline-block;
          font-size: 14px;
        }
      }

      .list-action {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          list-style-type: none;
          margin: 0 10px;

          a {
            font-size: 12px;
            color: #000;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            display: block;
            text-decoration: none !important;

            img {
              width: 24px;
              height: 24px;
              border: 0;
              display: block;
              margin: auto;
              margin-bottom: 7px;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .content {
    .content-box {
      width: 90%;
      padding: 30px;
    }

    .list-info {
      flex-direction: column;

      .info-detail {
        padding-left: 0;
      }
    }
  }
}
</style>

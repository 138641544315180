import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Login from "../views/Login.vue";
import NotFound from "../views/404.vue";
import Home from "../views/Home.vue";
import Main from "../views/Main.vue";
import Table from "../views/nav1/Table.vue";
import userOrder from "../views/userOrder";

let routes = [
  {
    path: "/login",
    component: Login,
    name: "",
    hidden: true,
    meta:{
      isPermission: []//1 普通用户 2 客服,
    },
  },
  {
    path: "/404",
    component: NotFound,
    name: "",
    hidden: true,
    meta:{
      isPermission: []//1 普通用户 2 客服,
    },
  },
  {
    path: "/",
    component: Home,
    name: "Home.home",
    hidden: true,
    iconCls: "el-icon-message", //图标样式class
    children: [
      { path: "/userOrder", component: userOrder, name: "Home.userOrder" },
      { path: "/table", component: Table, name: "Home.table" },
    ],
    meta:{
      isPermission: [1]//1 普通用户 2 客服,
    },
  },
  // {
  //   path: '/',
  //   component: userOrder,
  //   name: "提交工单",
  //   leaf: true,
  //   hidden: true,
  //   children: [
  //     { path: "/userOrder", component: userOrder, name: "提交工单" },
  //   ],
  //   meta:{
  //     isPermission: [2]//1 普通用户 2 客服,
  //   },
  // },

  // {
  //   path: "/",
  //   component: Home,
  //   name: "",
  //   iconCls: "fa fa-address-card",
  //   leaf: true, //只有一个节点
  //   children: [{ path: "/page6", component: Page6, name: "导航三" }],
  // },
  {
    path: "*",
    hidden: true,
    redirect: { path: "/404" },
    meta:{
      isPermission: []//1 普通用户 2 客服,
    },
  },
];

const router = new VueRouter({
  routes,
});
export default router;
